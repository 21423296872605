// Dependencies
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Squeeze as Hamburger } from "hamburger-react";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Local files
import resume from "../assets/BobQianResume.pdf";

// The navbar
const HeaderContainer = styled("div")`
    padding-top: 3em;
    padding-bottom: 3em;
    letter-spacing: 0.01em;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        flex-direction: column;
        align-items: stretch;
        font-size: 1.4em;

        padding-top: 1.75em;
        padding-bottom: 2em;
    }
`

// Contains site title (Bob Qian) and mobile monu
const TitleAndHamburger = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

// Site title (Bob Qian)
const SiteTitle = styled("div")`
    a {
        color: ${colors.grey800};
        text-decoration: none;
        font-weight: 600;

        transition: all 150ms ease-in-out; 

        &:hover {
            color: ${colors.bqblue500};
        }
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        margin-top: 0.1em; // Helps center against the hamburger icon
    }
`

// Hamburger icon (only displays on maxwidthMobile)
const HamburgerIcon = styled("div")`
    display: none;
    
    @media (max-width: ${dimensions.maxwidthMobile}px) {
        display: inline-block;
    }
`

// Contains site page links
const HeaderLinks = styled("div")`
    display: flex;
    gap: 1.5em;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        gap: 1em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5em;
        margin-top: 1em;
    }

    a {
        color: ${colors.grey800};
        text-decoration: none;

        transition: all 150ms ease-in-out; 
        
        transform: perspective(1px) translateZ(0);

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            text-align: right;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            transform: scaleX(0);
            transform-origin: 0 50%;
            
            transition: transform 200ms ease-out;

            background: linear-gradient(to top, ${colors.bqblue200} 50%, transparent 50%);
        }

        &:after {
            content: "";
            position: absolute;
            width: 50%;
        }

        &:hover {
            &:before {
                transform: scaleX(1);
            }

            color: black;
            transition: all 150ms ease-in-out;
        }

        &.active {
            background: linear-gradient(to top, ${colors.bqblue200} 50%, transparent 50%);
        }
    }
`

// Returns the nav links JSX
const displayNavLinks = () => (
    <>
        <Link
        activeClassName="active"
        to="/">
            Work
        </Link>
        
        <Link
        activeClassName="active"
        to="/about">
            About
        </Link>

        <a
        href={resume}
        target="_blank"
        rel="noreferrer">
            Resume
        </a>

        <Link
        activeClassName="active"
        to="/blog"
        partiallyActive={true}>
            Blog
        </Link>
    </>
);

// Function to render nav links
// Contains boolean fadeIn parameter
const displayHeaderLinks = (displayNavLinks, fadeIn) => {
    if (fadeIn) {
        return (
            <HeaderLinks
                as={motion.div}
                initial={{
                    y: 0,
                    opacity: 0
                }}
                animate={{
                    y: 0,
                    opacity: 1
                }}
                transition={{
                    type: "tween",
                    delay: 0.1,
                    ease: "easeInOut"
                }}
            >
                {displayNavLinks()}
            </HeaderLinks>
        );
    } else {
        return (
            <HeaderLinks>
                {displayNavLinks()}
            </HeaderLinks>
        );
    }    
}

const Header = () => {

    // Give a default value of 1000 as startingWidth if
    // current window is not defined
    const startingWidth = (typeof window !== "undefined") ? window.innerWidth : 1000;

    const [width, setWidth] = useState(startingWidth);
    
    // Function to check window validity and then update width
    const updateWidth = () => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", updateWidth);

            return () => {
                window.removeEventListener("resize", updateWidth);
            }
        }        
    }, []);

    const [navOpen, setNavOpen] = useState(false);
    const isMobile = (width <= dimensions.maxwidthMobile);
    

    return (
        <HeaderContainer>
            {/* Left side */}
            <TitleAndHamburger>
                <SiteTitle>
                    <Link to="/">Bob Qian</Link>
                </SiteTitle>

                <HamburgerIcon>
                    <Hamburger size={30} duration={0.4}
                    toggled={navOpen} toggle={setNavOpen}/>
                </HamburgerIcon>
            </TitleAndHamburger>

            {/* Render page links if not on mobile, or if mobile nav is open*/}
            {!isMobile ? 
                displayHeaderLinks(displayNavLinks, false)
                :
                navOpen && 
                displayHeaderLinks(displayNavLinks, true)
            }
        </HeaderContainer>
    );
}

export default Header;