// Dependencies
import React from "react";
import styled from "@emotion/styled";

// Styles
import colors from "../styles/colors";
import dimensions from "../styles/dimensions";

const FooterContainer = styled("div")`
    margin-top: 5em;
    padding-top: 3.75em;
    padding-bottom: 4em;
    
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
    font-size: 0.8em;
    letter-spacing: 0.02em;

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        flex-direction: column-reverse;
        font-size: 0.9em;
        align-items: stretch;
    }
`

// Footer links (LinkedIn, Dribbble, YouTube)
const LinkSection = styled("div")`
    display: flex;
    gap: 1.5em;
    color: ${colors.grey500};

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        gap: 1em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        flex-direction: column;
    }
    
    a {
        transition: all 150ms ease-in-out;
        color: currentColor;
        text-decoration: none;

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            border-top: 1px solid ${colors.grey300};
            padding-top: 1em;
            color: ${colors.grey600};
        }

        &:hover {
            color: ${colors.bqblue500};
            transition: all 150ms ease-in-out; 
        }
    }  
`

// "Made with love by Bob"
const Credits = styled("div")`
    color: ${colors.grey500};

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        margin-bottom: 1em;
        color: ${colors.grey400};
    }
    
    a {
        text-decoration: none;
        margin-left: 0.2em;
        margin-right: 0.2em;

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            margin-left: 0.15em;
            margin-right: 0.15em;
        }
    }
`

const Footer = () => (
    <FooterContainer>
        <LinkSection>
            <a 
            href="https://www.linkedin.com/in/bob-qian/"
            target="_blank"
            rel="noreferrer">LinkedIn</a> 
            
            <a 
            href="https://dribbble.com/bobqian"
            target="_blank"
            rel="noreferrer">Dribbble</a>
            
            <a 
            href="https://youtube.com/bqianmusic"
            target="_blank"
            rel="noreferrer">YouTube</a>
        </LinkSection>

        <Credits>
            Made with
            <a 
            href="https://ibighit.com/bts/eng/"
            target="_blank"
            rel="noreferrer">
                <span 
                className="emoji"
                role="img" 
                aria-label="purple heart">💜</span>
            </a>by Bob
        </Credits>
    </FooterContainer>
);

export default Footer;
