import css from "@emotion/css";
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';

const globalStyles = css`

    html, body, #root {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100%;
    }

    body {
        width: 100%;
        margin: 0 auto;
        
        color: ${colors.grey900};
        background-color: ${colors.grey000};
        -webkit-font-smoothing: antialiased;

        font-size: 22px;
        font-weight: 500; // 400 looks bad w/ Manrope
        line-height: 1.65;
        
        @media (max-width: ${dimensions.maxwidthMobile}px) {
            font-size: 18px;
        }

        @media (min-width: ${dimensions.minwidthXLscreen}px) {
            font-size: 26px;
        }

        * {
            box-sizing: border-box;

            &::selection {
                background: ${colors.bqblue500};
                color: ${colors.bqblue200};
            }
        }
    }

    // Styling inline code -- it's a custom Prismic.io label I made called "code"
    // Corresponds to a <span class="code"> element
    .code {
        font-family: 'JetBrains Mono', 'Courier New', Courier, monospace;
        font-size: 0.9em;
        
        color: ${colors.grey700};
        background-color: ${colors.grey300};
        border-radius: 4px;
        display: inline;
        padding: 0 0.3em 0 0.3em;
        
    }

    /* For making pretty emojis on Windows */
    .emoji {
        font-family: "Segoe UI Emoji";
        font-weight: normal;
    }

    /*
    A workaround for forcing accessibility wrappers
    to have a 100% height.
    Reach Router issue here: https: //github.com/reach/router/issues/63
    */
    #___gatsby,
    div[role="group"][tabindex] {
        height: 100%;
        min-height: 100% !important;
    }
`

export default globalStyles;