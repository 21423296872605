// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { Global } from "@emotion/core";

// Styles
import dimensions from "styles/dimensions";
import globalStyles from 'styles/global';
import typeStyles from 'styles/typography';
import 'styles/fonts.scss';

// Components
import Footer from "components/Footer";
import Header from "components/Header";

const LayoutContainer = styled("div")`
    max-width: ${dimensions.maxwidthDesktop}px;
    padding-left: ${dimensions.paddingHorizontalDesktop}em;
    padding-right: ${dimensions.paddingHorizontalDesktop}em;
    margin: 0 auto;

    @media (min-width: ${dimensions.minwidthXLscreen}px) {
        max-width: ${dimensions.minwidthXLscreen}px;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        padding-left: ${dimensions.paddingHorizontalTablet}em;
        padding-right: ${dimensions.paddingHorizontalTablet}em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        padding-left: ${dimensions.paddingHorizontalMobile}em;
        padding-right: ${dimensions.paddingHorizontalMobile}em;
    }
`

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <LayoutContainer>
                <Global styles={[globalStyles, typeStyles]} />
                    <div>
                        <Header />
                        <main>{children}</main>
                        <Footer />
                    </div>
            </LayoutContainer>
        )}
    />
);

// Ensure children type
Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;
