import css from '@emotion/css';

const typeStyles = css`
    a {
        &:hover {
            cursor: pointer;
        }
    }

    // H1: hero line #1
    h1 {
        font-size: 2.8em;
        font-weight: 800;
        line-height: 1.2;
    }

    // H2: hero line #2, project and blog page titles
    h2 {
        font-size: 1.9em;
        font-weight: 700;
        line-height: 1.2;

        margin-bottom: 0.3em;
    }

    // H3: project and blog card titles, article headings
    h3 {
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.3;
        
        margin-top: 1.5em;
        margin-bottom: 0em;
    }

    // H4: article subheadings
    h4 {
        font-size: 1em;
        font-weight: 700;
        
        margin-top: 2em;
        margin-bottom: -0.5em;
    }

    // H5: blog post page category
    h5 {
        font-size: 0.8em;
        font-weight: 400;
        line-height: 1.25;

        margin-bottom: 1em;

        letter-spacing: 0.02em;
    }

    // H6: project card "category" (tech used)
    h6 {
        font-size: 0.9em;
        font-weight: 700;
        margin: 0;
    }

    p {
        letter-spacing: 0.01em;
        margin-block-start: 1.5em;
        margin-block-end: 1.5em;
        line-height: 1.75;
    }
`

export default typeStyles;