//Dimensions for device media queries and layout padding
const dimensions = {
    //pixels
    minwidthXLscreen: 1600,
    maxwidthDesktop: 1200, // prev 1100
    maxwidthTablet: 768,
    maxwidthMobile: 600,

    //ems
    paddingHorizontalDesktop: 3,
    paddingHorizontalTablet: 2.5,
    paddingHorizontalMobile: 1.5,
}

export default dimensions;