//theme colors and tints (dark900 to normal500 to light100)

// use https://maketintsandshades.com/

// input 500, then take every other color

const colors = {
    blue900: "#192c4c",
    blue800: "#2a4a7f",
    blue700: "#3a67b2",
    blue600: "#4b84e5",
    blue500: "#5393fe",
    blue400: "#73abff",
    blue300: "#cbdfff",
    blue200: "#e3efff",
    blue100: "#f1f8ff",

    green600: "#5C9B3E",
    green500: "#6AC73E",
    green200: "#E6F4DF",
    green100: "#",

    grey900: "#111010",
    grey850: "#272829",
    grey800: "#3D3F42",
    grey700: "#6e7073",
    grey600: "#A9AAAB",
    grey500: "#BEBFC0",
    grey400: "#D4D4D5",
    grey300: "#E9EAEA",
    grey200: "#F2F2F2",
    grey100: "#F9F9FA",
    grey000: "#FAFAFA",

    orange600: "#D86E4E",
    orange500: "#F38350",
    orange200: "#FFEAE2",
    orange100: "#",

    pink900: "#804980",
    pink800: "#B366B3",
    pink700: "#CC74CC",
    pink600: "#E683E6",
    pink500: "#FF91FF",
    pink400: "#FFA7FF",
    pink300: "#FFC8FF",
    pink200: "#FFFEFF",
    pink100: "#FFF4FF",

    purple900: "#",
    purple800: "#",
    purple700: "#",
    purple600: "#9064C9",
    purple500: "#BD89FF",
    purple400: "#",
    purple300: "#",
    purple200: "#F0E5FF",
    purple100: "#",

    teal600: "#159f9f",
    teal500: "#17B1B1",
    teal200: "#D1EFEF",
    teal100: "#E8F7F7",

    dukeblue700: "#03102e",
    dukeblue600: "#000a1f",
    dukeblue500: "#012169",
    dukeblue200: "#d9e6ff",

    amazonorange700: "#f59402",
    amazonorange600: "#f79707",
    amazonorange500: "#ff9900",
    amazonorange200: "#ffebcc",

    bqblue900: "#",
    bqblue800: "#",
    bqblue700: "#",
    bqblue600: "#0092cc",
    bqblue500: "#00b7ff",
    bqblue400: "#",
    bqblue300: "#ccf1ff",
    bqblue200: "#e6f8ff",
    bqblue100: "#F1F9FC",
}

export default colors;